import './Result.css'
import PulseRingsMultiple from "../components/Loader.tsx";
import { Link } from "react-router-dom";
import Speedometer from '../components/Speedometer.js';

const threshold = 4;

function convert_name(s) {
    return s.replace('#', '%23');
  }

function up_first_letter(s) {
    return s.charAt(0).toUpperCase() + s.slice(1);
}


export function convert_score(doomscore) {
    // let doomonumber = Number(doomscore.toFixed(0))*4.16665;

    // if (doomonumber > 16.6 ) {
    //     doomonumber = 16.6 + Math.log(doomonumber-16.6)*5
    // }
    // else if (doomonumber < 16.6) {
    //     doomonumber = -16.6 - Math.log(-doomonumber-16.6)*5
    // }

    // doomonumber = 50 - doomonumber;
    // return doomonumber;

    
    // return 110/Math.PI * Math.atan(-doomscore/7) + 50;
    return 110/Math.PI * Math.atan(-doomscore/6.4) + 50;
}

function parse_rank(trank) {
    let parsed_tier = "";
    switch(trank["tier"]) {
        case "I":
            parsed_tier = " I";
            break;
        case "II":
            parsed_tier = " II";
            break;
        case "III":
            parsed_tier = " III";
            break;
        case "IV":
            parsed_tier = " IV";
            break;
        default:
            parsed_tier = "";
            break;
            
    }
    return {"rank": up_first_letter(trank["rank"].toLowerCase()), "tier": parsed_tier, "lp": trank["lp"]};
}


function get_userside_data(data) {
    //{ "doom-score": -3.297000801691567, "avg-kda_t1": 3.6441067821067827, "avg-kda_t2": 3.8676825396825394, "avg-wr_t1": 0.5858237352145395, "avg-wr_t2": 0.5447798359051788, "avg-wrr_t1": 0.5399999999999999, "avg-wrr_t2": 0.5599999999999999, "op-team": 1 }
    const team = data["op-team"];

    return {
        "avg-kda": data[`avg-kda_t${team}`], 
        "avg-wr": data[`avg-wr_t${team}`], 
        "avg-wrr": data[`avg-wrr_t${team}`],
        "avg-rank": parse_rank(data[`ranked_t${team}`])
    };
}

function get_counterside_data(data) {
    const cteam = 3 - parseInt(data["op-team"]); // 3 - opteam => team opposée

    return {
        "avg-kda": data[`avg-kda_t${cteam}`], 
        "avg-wr": data[`avg-wr_t${cteam}`], 
        "avg-wrr": data[`avg-wrr_t${cteam}`],
        "avg-rank": parse_rank(data[`ranked_t${cteam}`])
    };
}



function Loader(user) {
    return (
        <div className='res-wrapper'>
            <div className='loader-container'>
                <center>
                <PulseRingsMultiple/>
                </center>
            </div>
            <br/>
            Analyzing {user}'s account...
            <br/>
            <h5>ETA : 8.5s</h5>
        </div>
    );
}

function ResTitle(score, ingame) {

    if (score < -threshold) {
        return (
            <div className='title-div'>
                <p className='title-text-container'>
                    You {ingame ? "were" : "are"} 
                </p>
                <div className='doomed-text'>DOOMED</div>
            </div>
        )
    }
    else if (score <= threshold) {
        return (
            <div className='title-div'>
                <p className='title-text-container'>
                    You {ingame ? "were" : "are"} 
                </p>
                <div className='neutral-text'>Even</div>
            </div>
        )
    }
    else {
        return (
            <div className='title-div'>
                <p className='title-text-container'>
                    You {ingame ? "were" : "are"} 
                </p>
                <div className='winner-text'>Winning</div>
            </div>
        )
    }
}


function Display_team_stats(team_data, adapt_color) {
    return (
        <div className='team-stats-wrapper'>
            <div className='team-stat'>
                <h3 style={{color : adapt_color}}>{Number(team_data["avg-kda"]).toFixed(2)}</h3>
                <h5> Average <br/> kda</h5>
            </div>
            <div className='team-stat'>
                <h3 style={{color : adapt_color}}>{Number((team_data["avg-wr"]*100).toFixed(2))}%</h3>
                <h5> Split <br/> winrate </h5>
            </div>
            <div className='team-stat'>
                <h3 style={{color : adapt_color}}>{Number((team_data["avg-wrr"]*100).toFixed(2))}%</h3>
                <h5> Recent <br/> winrate </h5>
            </div>
        </div>
    );
}


function ResDisplay(user, data) {



    const team_data = get_userside_data(data);
    const cteam_data = get_counterside_data(data);

    const ingame = data["in-game"];


    const doomonumber = convert_score(data["doom-score"]);
    

    let adapt_color = "#2f41f0";
    if (data["doom-score"] < -threshold) {
        adapt_color = "red";
    }   
    else if (data["doom-score"] > threshold) {
        adapt_color = "greenyellow";
    }

    return (
        
        
        <div className='res-wrapper'>

            <div className='top-text'>
                {user}'s {ingame ? "last" : "current"} game analysis:
            </div>
            {ResTitle(data["doom-score"], ingame)}


            <div className='acc-stats'>
                <div className='team-wrapper'>
                    <div className='team-render'>

                        <div className='team-section'>
                            <img
                                className="team-image"
                                src={require("./content/ally.png")}
                                alt="ally-image"
                            />
                            <div className='team-text-wrapper'>
                                Your team
                                <h4>Average team statistics</h4>
                            </div>
                        </div>

                        {Display_team_stats(team_data, adapt_color)}


                    

                        <div className='team-section-rank'>
                            <img
                                className="team-rank"
                                src={require(`./content/ranks/Rank=${team_data["avg-rank"]["rank"]}.png`)}
                                alt={`${team_data["avg-rank"]["rank"]}`}
                            />
                            <div className='team-text-wrapper-rank'>
                                {team_data["avg-rank"]["rank"]}{team_data["avg-rank"]["tier"]} | {team_data["avg-rank"]["lp"]} lp
                                <h4>Your team's average rank</h4>
                            </div>
                        </div>
                    </div>
                    <div className='counter-team-render'>

                        <div className='team-section'>
                            <img
                                className="team-image"
                                src={require("./content/enemy.png")}
                                alt="enemy-image"
                            />
                            <div className='team-text-wrapper'>
                                Enemy team
                                <h4>Average team statistics</h4>
                            </div>
                        </div>

                        {Display_team_stats(cteam_data, adapt_color)}

                        <div className='team-section-rank'>
                            <img
                                className="team-rank"
                                src={require(`/src/Pages/content/ranks/Rank=${cteam_data["avg-rank"]["rank"]}.png`)}
                                alt={`${cteam_data["avg-rank"]["rank"]}`}
                            />
                            <div className='team-text-wrapper-rank'>
                                {cteam_data["avg-rank"]["rank"]}{cteam_data["avg-rank"]["tier"]} | {cteam_data["avg-rank"]["lp"]} lp
                                <h4>Enemy team's average rank</h4>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='doom-score-final'>


                    <Speedometer value={doomonumber} tcolor={adapt_color} threshold={Number((convert_score(threshold)).toFixed(0))} user={user}/>
                    <br/>
                        {/* Your final doom score: <h3 style={{color : adapt_color}}>{Number(data["doom-score"].toFixed(3))}</h3> */}
                        <div style={{color: "#5d6676", fontSize: "1.2vw"}}>
                            Learn more about doomscore <Link style={{color: "whitesmoke"}} to="/technicals">here</Link>
                        </div>
                </div>

                <SearchAgain/>

            </div>
        </div>
    );  
}


function Return2Front() {
    return (
        <div>
        <Link className='return-button' to='/'>
            Return to front page
        </Link>
        </div>
    )
}

function ToLastGame(user, server) {
    return (
        <div>
        <Link className='passive-button' to= {`/result/last/${server}/${convert_name(user)}`}>
            Scan last game
        </Link>
        </div>
    )
}

function SearchAgain() {
    return (
        <div style={{marginBottom: "20px", marginTop: "2%"}}>
            <Link className='passive-button' to= "/">
                Scan again
            </Link>
        </div>
    )
}

function Retry() {
    return (
        <div>
        <Link className='passive-button' id="retry-button" onClick={() => {window.location.reload()}}>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" width="70%" height="70%" style={{marginRight: "-22%", marginLeft: "-28%", marginTop: "2%"}}>
                <path d="M463.5 224l8.5 0c13.3 0 24-10.7 24-24l0-128c0-9.7-5.8-18.5-14.8-22.2s-19.3-1.7-26.2 5.2L413.4 96.6c-87.6-86.5-228.7-86.2-315.8 1c-87.5 87.5-87.5 229.3 0 316.8s229.3 87.5 316.8 0c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0c-62.5 62.5-163.8 62.5-226.3 0s-62.5-163.8 0-226.3c62.2-62.2 162.7-62.5 225.3-1L327 183c-6.9 6.9-8.9 17.2-5.2 26.2s12.5 14.8 22.2 14.8l119.5 0z" fill="white"/>
            </svg>
            Retry
        </Link>
        </div>
    )
}


function Display_code32(user, server) {
    return (
        <div className='error-wrapper'>
            User {user} does not exist on {server} servers.
            <br/>
            <Return2Front/>
        </div>
    );
}

function Display_code33(user) {
    return (
        <div className='error-wrapper'>
            User {user} has never played a ranked game during this split.
            <br/>
            <Return2Front/>
        </div>
    );
}

function Display_code34(user, server) {
    return (
        <div className='error-wrapper'>
            User {user} is currently not ingame.
            <br/>
            <div className='error-button-wrapper'>
                {Retry(user, server)}
                {ToLastGame(user, server)}
            </div>
        </div>
    );
}

function Display_code35(user, server) {
    return (
        <div className='error-wrapper'>
            User {user} is not in a ranked game.
            <br/>
            <div className='error35-button-wrapper'>
                {ToLastGame(user, server)}
                <Return2Front/>
            </div>
        </div>
    );
}

function Display_code500(user) {
    return (
        <div className='error-wrapper'>
            Internal server error
            <br/>
	    <h5>Please try again later</h5>
	    <div style={{height: "0.5vw"}}/>
            <Return2Front/>
        </div>
    );
}


export default function Result_main(user, server, dataout, setdata, current) {

    function handleData(data) {
	    if (data === null) {
	        setdata({"code": 500});
	    }
	    else {
            setdata(data);
	    }
    }
    
    if (user === "test#EUW" && dataout === undefined) {
        setdata({
            "avg-kda_t1": 3.026883116883117,
            "avg-kda_t2": 3.4090371244916695,
            "avg-wr_t1": 0.5283523870376444,
            "avg-wr_t2": 0.5266138341051328,
            "avg-wrr_t1": 0.41818181818181815,
            "avg-wrr_t2": 0.43636363636363634,
            "avg_rank_t1": 3130,
            "avg_rank_t2": 3330,
            "code": 200,
            "doom-score": -10.697273419242634,
            "in-game": 1,
            "op-team": 1,
            "ranked_t1": { rank: "MASTER", tier: "III", lp: 330 },
            "ranked_t2": { rank: "MASTER", tier: "II", lp: 530 }
        });
        return (Loader(user));
    }

    if (dataout === undefined) {
        if (current) {
            fetch(`https://www.amidoomed.app/api/calculate/${server}/${convert_name(user)}`, {
            // fetch(`https://www.amidoomed.app/api/calculate/${convert_name(user)}`, {
                mode: 'cors',
                headers: {
                    "Access-Control-Allow-Origin": '*',
                    "Access-Control-Allow-Headers": "Origin, X-Requested-With, Content-Type, Accept"
            }}
            ).catch((e) => {
                handleData(null);
            }).then(res => res.json()).then(data => handleData(data));
        }
        else {
            fetch(`https://www.amidoomed.app/api/calculate/last/${server}/${convert_name(user)}`, {
            // fetch(`https://www.amidoomed.app/api/calculate/last/${convert_name(user)}`, {
                mode: 'cors',
                headers: {
                    "Access-Control-Allow-Origin": '*',
                    "Access-Control-Allow-Headers": "Origin, X-Requested-With, Content-Type, Accept"
            }}
            ).catch((e) => {
                handleData(null);
            }).then(res => res.json()).then(data => handleData(data));
        }

        return (Loader(user));


    }
    else if (dataout["code"] === 500) {
        return (Display_code500(user));
    }
    else if (dataout["code"] === 32) {
        return (Display_code32(user, server));
    }
    else if (dataout["code"] === 33) {
        return (Display_code33(user));
    }
    else if (dataout["code"] === 34) {
        return (Display_code34(user, server));
    }
    else if (dataout["code"] === 35) {
        return (Display_code35(user, server));
    }
    else {
        return (ResDisplay(user, dataout));
    };
}
