import './Navbar.css';
import { Link } from "react-router-dom";


export default function Navbar(pageid) {

    return (
        <div className='navbar-container' id="navbar">
            <div className='left-logo'>
                <Link to="/">
                    {
                        pageid === "front" ? 
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" width="40px" height="40px">
                                <path d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM164.7 328.7c22-22 53.9-40.7 91.3-40.7s69.3 18.7 91.3 40.7c11.1 11.1 20.1 23.4 26.4 35.4c6.2 11.7 10.3 24.4 10.3 35.9c0 5.2-2.6 10.2-6.9 13.2s-9.8 3.7-14.7 1.8l-20.5-7.7c-26.9-10.1-55.5-15.3-84.3-15.3h-3.2c-28.8 0-57.3 5.2-84.3 15.3L149.6 415c-4.9 1.8-10.4 1.2-14.7-1.8s-6.9-7.9-6.9-13.2c0-11.6 4.2-24.2 10.3-35.9c6.3-12 15.3-24.3 26.4-35.4zm-31.2-182l89.9 47.9c10.7 5.7 10.7 21.1 0 26.8l-89.9 47.9c-7.9 4.2-17.5-1.5-17.5-10.5c0-2.8 1-5.5 2.8-7.6l36-43.2-36-43.2c-1.8-2.1-2.8-4.8-2.8-7.6c0-9 9.6-14.7 17.5-10.5zM396 157.1c0 2.8-1 5.5-2.8 7.6l-36 43.2 36 43.2c1.8 2.1 2.8 4.8 2.8 7.6c0 9-9.6 14.7-17.5 10.5l-89.9-47.9c-10.7-5.7-10.7-21.1 0-26.8l89.9-47.9c7.9-4.2 17.5 1.5 17.5 10.5z" fill="#f5f5f5"/>
                            </svg> 
                        : 
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" width="40px" height="40px">
                                <path d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM164.7 328.7c22-22 53.9-40.7 91.3-40.7s69.3 18.7 91.3 40.7c11.1 11.1 20.1 23.4 26.4 35.4c6.2 11.7 10.3 24.4 10.3 35.9c0 5.2-2.6 10.2-6.9 13.2s-9.8 3.7-14.7 1.8l-20.5-7.7c-26.9-10.1-55.5-15.3-84.3-15.3h-3.2c-28.8 0-57.3 5.2-84.3 15.3L149.6 415c-4.9 1.8-10.4 1.2-14.7-1.8s-6.9-7.9-6.9-13.2c0-11.6 4.2-24.2 10.3-35.9c6.3-12 15.3-24.3 26.4-35.4zm-31.2-182l89.9 47.9c10.7 5.7 10.7 21.1 0 26.8l-89.9 47.9c-7.9 4.2-17.5-1.5-17.5-10.5c0-2.8 1-5.5 2.8-7.6l36-43.2-36-43.2c-1.8-2.1-2.8-4.8-2.8-7.6c0-9 9.6-14.7 17.5-10.5zM396 157.1c0 2.8-1 5.5-2.8 7.6l-36 43.2 36 43.2c1.8 2.1 2.8 4.8 2.8 7.6c0 9-9.6 14.7-17.5 10.5l-89.9-47.9c-10.7-5.7-10.7-21.1 0-26.8l89.9-47.9c7.9-4.2 17.5 1.5 17.5 10.5z" fill="#434f64"/>
                            </svg>
                    }
                </Link>
            </div>

            <div className='right-container'>
                {pageid === "abus" ? 
                    <Link to="/about_us">
                        <div className='abus-container' style={{color: "#f5f5f5", borderBottom: "solid 3px #434f64"}}>
                            About us
                        </div>
                    </Link>
                    : 
                    
                    <Link to="/about_us">
                        <div className='abus-container' style={{color: "#434f64"}}>
                            About us
                        </div>
                    </Link>
                    }

                {pageid === "techs" ? 
                    <Link to="/technicals">
                        <div className='abus-container' style={{color: "#f5f5f5", borderBottom: "solid 3px #434f64"}}>
                            Technicals
                        </div>
                    </Link>
                    : 
                    
                    <Link to="/technicals">
                        <div className='abus-container' style={{color: "#434f64"}}>
                            Technicals
                        </div>
                    </Link>
                    }

                {pageid === "donate" ? 
                    // changer quand besoin
                    <Link to="https://www.patreon.com/amidoomed?utm_medium=unknown&utm_source=join_link&utm_campaign=creatorshare_creator&utm_content=copyLink">
                        <div className='abus-container' style={{color: "#f5f5f5", borderBottom: "solid 3px #434f64"}}>
                            <div className="inline-block" style={{marginRight:"7px"}}>
                            Donate 
                            </div>
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" fill="#c63e3e" heigh="20px" width="20px" className='inline-block'>
                                <path d="M47.6 300.4L228.3 469.1c7.5 7 17.4 10.9 27.7 10.9s20.2-3.9 27.7-10.9L464.4 300.4c30.4-28.3 47.6-68 47.6-109.5v-5.8c0-69.9-50.5-129.5-119.4-141C347 36.5 300.6 51.4 268 84L256 96 244 84c-32.6-32.6-79-47.5-124.6-39.9C50.5 55.6 0 115.2 0 185.1v5.8c0 41.5 17.2 81.2 47.6 109.5z"/>
                            </svg>
                        </div>
                    </Link>
                    : 
                    // changer quand besoin
                    <Link to="https://www.patreon.com/amidoomed?utm_medium=unknown&utm_source=join_link&utm_campaign=creatorshare_creator&utm_content=copyLink">
                        <div className='abus-container' style={{color: "#434f64"}}>
                            <div className="inline-block" style={{marginRight:"7px"}}>
                                Donate 
                            </div>
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" fill="#c63e3e" heigh="20px" width="20px" className='inline-block'>
                                <path d="M47.6 300.4L228.3 469.1c7.5 7 17.4 10.9 27.7 10.9s20.2-3.9 27.7-10.9L464.4 300.4c30.4-28.3 47.6-68 47.6-109.5v-5.8c0-69.9-50.5-129.5-119.4-141C347 36.5 300.6 51.4 268 84L256 96 244 84c-32.6-32.6-79-47.5-124.6-39.9C50.5 55.6 0 115.2 0 185.1v5.8c0 41.5 17.2 81.2 47.6 109.5z"/>
                            </svg>
                        </div>
                    </Link>
                    }

                {/* <div className='donate-container'>
                    <a href="https://www.patreon.com/user?u=138068064">
                        <div className="inline-block" style={{marginRight:"7px"}}>
                            Donate 
                        </div>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" fill="#c63e3e" heigh="20px" width="20px" className='inline-block'>
                            <path d="M47.6 300.4L228.3 469.1c7.5 7 17.4 10.9 27.7 10.9s20.2-3.9 27.7-10.9L464.4 300.4c30.4-28.3 47.6-68 47.6-109.5v-5.8c0-69.9-50.5-129.5-119.4-141C347 36.5 300.6 51.4 268 84L256 96 244 84c-32.6-32.6-79-47.5-124.6-39.9C50.5 55.6 0 115.2 0 185.1v5.8c0 41.5 17.2 81.2 47.6 109.5z"/>
                        </svg>
                    </a>
                </div> */}

            </div>

        </div>
    )

}