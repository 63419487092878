import './App.css';
import Front from "./Pages/Front.js";
import Navbar from './components/Navbar.js';
import Aboutus from './Pages/About_us.js';
import Result from './Pages/Result_wrapper.js';
import Error404 from './components/Error.js';
import DocumentMeta from 'react-document-meta';
import Technicals from './Pages/Technicals.js';
import Donate from './Pages/Donate.js';


function Main(pageid, user, server) {


    function switch_wrapper(pid) {
        switch (pid) {
            case "front":
                return (<Front/>);
            case "donate":
                return (<Donate/>);
            case "res":
                return (<Result username={user} server={server} current={1}/>);
            case "lastres":
                return (<Result username={user} server={server} current={0}/>);
            case "404":
                return (<Error404/>);
            case "abus":
                return (<Aboutus/>);
            case "techs":
                return (<Technicals/>);
            case "err":
                return (<Front/>);
            default:
                return (<h1>INTERNAL ERROR</h1>);
        }
    }

    const meta = {
        title: 'Am I DOOMED - Are you meant to lose',
        description: 'AmIDOOMED is a League of Legends community project that tells you if you are likely to lose your games',
        canonical: 'https://www.amidoomed.app/',
        meta: {
            charset: 'utf-8',
            name: {
                keywords: "League of Legends,LoL,loser's queue,loser queue,looser queue,loser's q,loser q,looser q,looser's q,loosers queue,losers queue,lol calculator,amidoomed,am i doomed,doomed,lol tool,lol fun tool,am i losing,am i in,winner's queue,winner's q,winners queue,winners q,w q,winners q,how to not be,top diff,sup diff,jungle diff,jgl diff,adc gap,sup diff,supp diff,jgl gap,jungle gap,top gap,mid diff,am i losing,game analyzer,bot diff,bot gap,bot abyss,lol team,team gap,team diff,why is my team bad,lol better team,help me,team analyzer,league,doomed,how to win,league of legends am i in losers queue,lol am i in losers queue,lol am i in l queue,lol am i in losers q,lol losers queue,lol why is my team bad,lol team analysis,league of legends team analysis,am i doomed,lol am i in loser's queue,league of legends am i in loser's queue,how to win,team comp"
            }
        }
      };

    return (
    <div className="App" id="app">

        <DocumentMeta {...meta} />
        <header className="App-header">
            {Navbar(pageid)}
            {switch_wrapper(pageid)}
        </header>
      </div>
    );
  }
  
  export default Main;
