import './Front.css';
import SearchBar from '../components/SearchBar.js';
import Stats from './Stats.js';
import Add from '../components/Add.js';
import { Link } from "react-router-dom";


function Front() {


  return (
    <div className="Front">
        
        <div className='title'>
            <p className='title-text-container'>
                Am I <span className='doomed'>DOOMED</span> <span className='qmark'>?</span> 
            </p>
        </div>

        <SearchBar/>
        <div className='disclaimer'>
          
          This project is still in developement more regions and features are coming soon.
          <br/>
          Enter your Summoner's name to analyse your ranked games and find out how likely you are to win. <Link style={{color: "white"}} to="/technicals">More details</Link>
        </div>

        <Add/>

        <Stats/>
    </div>
  );
}

export default Front;
