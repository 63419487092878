


export default function Add() {
    return (
        <div>
            <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-5231379467197327" crossOrigin="anonymous"></script>
            <ins className="adsbygoogle"
                style={{display:"block"}}
                data-ad-client="ca-pub-5231379467197327"
                data-ad-slot="4115576340"
                data-ad-format="auto"
                data-full-width-responsive="true"></ins>
            <script>
                (adsbygoogle = window.adsbygoogle || []).push({});
            </script>
        </div>
    )
}