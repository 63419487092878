import "./Footer.css";



function Webplan() {
    
}

function Legals() {
    // This website is for entertainment purposes only, results should by no mean be taken seriously. You shall always try your best to win every game you play.
    return (
        <div className="legal">
            <div className="legal-text">
                AmIDOOMED isn't endorsed by Riot Games and doesn't reflect the views or opinions of Riot Games or anyone officially involved in producing or managing League of Legends. League of Legends and Riot Games are trademarks or registered trademarks of Riot Games, Inc. League of Legends © Riot Games, Inc. 
            </div>
            <div className="socials">
                <a href="https://www.patreon.com/amidoomed?utm_medium=unknown&utm_source=join_link&utm_campaign=creatorshare_creator&utm_content=copyLink">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" fill="#434f64" height="2vw" width="2vw" className="social">
                        <path d="M489.7 153.8c-.1-65.4-51-119-110.7-138.3C304.8-8.5 207-5 136.1 28.4C50.3 68.9 23.3 157.7 22.3 246.2C21.5 319 28.7 510.6 136.9 512c80.3 1 92.3-102.5 129.5-152.3c26.4-35.5 60.5-45.5 102.4-55.9c72-17.8 121.1-74.7 121-150z"/>
                    </svg>
                </a>
                <a href="https://x.com/amidoomed_app">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" fill="#434f64" height="2vw" width="2vw" className="social">
                        <path d="M389.2 48h70.6L305.6 224.2 487 464H345L233.7 318.6 106.5 464H35.8L200.7 275.5 26.8 48H172.4L272.9 180.9 389.2 48zM364.4 421.8h39.1L151.1 88h-42L364.4 421.8z"/>
                    </svg>
                </a>
            </div>
        
        </div>
    )
}

export default function Footer() {



    return (


        <div className="footer-wrapper">
            
            <p className="text-random">
                This website is for entertainment purposes only, results should by no mean be taken seriously. You shall always try your best to win every game you play. 
            </p>
            <Webplan/>
            <div className="footer-separator"></div>

            <Legals/>
        </div>
    )
}
