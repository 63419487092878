import Error404 from "../components/Error";
import Result_main from "./Result";
import { useState } from "react";


export default function Result(props) {

    const [dataout, setdata] = useState(undefined);
    let user = props.username;
    let server = props.server;
    let current = false;
    if (props.current === 1) {
        current = true;
    }
    if (user.indexOf("#") === -1) {
        if (server === "NA") {
            user = user.concat('', "#NA1");
        }
        else {
            user = user.concat('', `#${server}`);
        }
    }
    if (server !== "EUW" && server !== "EUNE" && server !== "NA") {
        return (
            <Error404/>
        )
    }
    return (
        Result_main(user, server, dataout, setdata, current)
    );

}