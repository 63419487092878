import './About_us.css';
import { Link } from "react-router-dom";

export default function Aboutus() {




  return (
    <div className="abus-wrapper">
        
        <div className='abus-title'>
            <p className='abus-title-text-container'>
              <Link to="/">
                Am I <span className='abus-doomed'>DOOMED</span> <span className='abus-qmark'>?</span> 
              </Link>
            </p>
        </div>

        <div className='abus-description'>
          {/* AM I DOOMED is a community made tool for League of Legends that scans user game and evaluate the likeniless of loosing the game STATISTICALLY based and symbolized by the DOOMscore.<br/>
          To start a scan just enter your Game name followed by your #tag like this "GameName#Tag". <br/>
          The DOOMscore goes from 0 to 100, may you be saved of a 100. The less likely you are to win the higher your doomscore will be.<br/>
          Scores between 30 and 70 means the game is pretty fairly balanced and that there is not major disparity between teams based on their statistics.<br/>
          You can start a search for your current game as soon as champion select is over and the game is loading.<br/>
          If you are not in a game or your game just ended you can scan your last game/the game that just ended to know wether the result matched the algorithm expectations.<br/>
          DISCLAIMER : <br/>
          You can still win with a high score and it does happen pretty often that players with a high DOOMscore win their game. <br/>
          We are all human and everybody can make mistake so even tho your DOOMscore is near 0 you can always loose the game.<br/>
          Thus, you should always try your best in game.<br/> */}
          AM I DOOMED is a community-made tool for League of Legends that scans ranked user games and evaluates <br/>the likeliness of losing the game based on STATISTICS symbolized by the DOOMscore.<br/>
          To start a scan just enter your Game name followed by your #tag like this "GameName#Tag".<br/>
          The higher the score the more doomed you are. <Link style={{color: "#6A82C4"}} to="/technicals">More details</Link>

        </div>

        <div className='questions-wrapper'>
            <div className='question-container'>
              <div className='question'>
                - Who are we?
              </div>
              <div className='response'>
			          We are two computer science students and more than anything League of Legends players. We were interested in making a project surrounding this game that we all know and belove. We run this project at our own expense, you can support on <Link style={{color: "#ff3c00"}} to="https://www.patreon.com/amidoomed?utm_medium=unknown&utm_source=join_link&utm_campaign=creatorshare_creator&utm_content=copyLink">Patreon©</Link>
              </div>
            </div>
            <div className='questions-separator'/>
            <div className='question-container'>
              <div className='question'>
                - Why build the site?
              </div>
              <div className='response'>
                As active members of League of Legends community, we often find ourselves in very difficult games. We then decided to create a tool to peace and reassure ourselves that we are not bad and are just being tested by riot's holy matchmaking algorithm, we then let the idea grow by itself and added features that we thought users might enjoy.
              </div>
            </div>
        </div>



        <div className='privacy-wrapper'>
            <div className='privacy-title'>
              Regarding privacy:
            </div>
            <p className='privacy-yap'>
              During your account's scan we collect data regarding your game account including the level you are at during the scan, your summoner's name, your game history and personal performances on said games. <br/>
              We also may automatically collect the following information about your use of our Sites or Services through cookies and other technologies: your domain name; your browser type and operating system; web pages you view; links you click; your IP address; your MAC address; your mobile device ID; your ADID(Advertising ID) and IDFA(ID for Advertisers); your Service usage and history; the length of time you visit our Sites or Services; and the referring URL, or the webpage that led you to our Sites or it includes, but is not limited to, etc.(Hereinafter, 'Automatically Collected Information'). 
            </p>
        </div>

    </div>
  );
}

