import './App.css';
import Main from './Main.js';
import { BrowserRouter as Router, Routes, Route, useParams } from "react-router-dom";
import Footer from './components/Footer.js';
import Maintenance from './Pages/Maintenance.js';

const MAINTENANCE = false;


function ResWrapper() {
  let {server, user} = useParams();
  return (
    <div>
      {Main("res", user, server)}
    </div>
  );
}

function LastResWrapper() {
  let {server, user} = useParams();
  return (
    <div>
      {Main("lastres", user, server)}
    </div>
  );
}

function App() {

  if (MAINTENANCE) {
    return (
      <Maintenance/>
    );
  }
  else {

    return (
      <Router>
            <div>
                <Routes>
                    <Route path="/about_us" element={Main("abus", "none", "none")}/>
                    <Route path="/donate" element={Main("donate", "none", "none")}/>
                    <Route path="/technicals" element={Main("techs", "none", "none")}/>
                    <Route path="/result/:server/:user" element={<ResWrapper/>}/>
                    <Route path="/result/last/:server/:user" element={<LastResWrapper/>}/>
                    <Route path="/error" element={Main("err", "none", "none")} status={400}/>
                    <Route path="/" element={Main("front", "none", "none")} loader={<div style={{backgroundColor:'black', width:"100%"}}>hi</div>}/>
	  	              <Route path="*" element={Main("404", "none", "none")} status={400}/>
                </Routes> 
            </div>
            <Footer/>
      </Router>
    );
  }
}

export default App;
