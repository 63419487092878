import './About_us.css';
import { Link } from "react-router-dom";

export default function Technicals() {




  return (
    <div className="abus-wrapper">
        <div className='abus-title'>
            <p className='abus-title-text-container'>
              <Link to="/">
                Am I <span className='abus-doomed'>DOOMED</span> <span className='abus-qmark'>?</span> 
              </Link>
            </p>
        </div>


        <div className='questions-wrapper'>
            <div className='question-container'>
              <div className='question'>
                - How to use amidoomed ?
              </div>
              <div className='response'>
              AM I DOOMED is a community-made tool for League of Legends that scans ranked user games and evaluates the likeliness of losing the game based on STATISTICS which are compiled and symbolized by the DOOMscore. <br/>
                To start a scan just enter your Game name followed by your #tag like this "GameName#Tag". You can start a search for your current game as soon as champion select is over and the game is loading.
              </div>
            </div>

            <div className='questions-separator'/>

            <div className='question-container'>
              <div className='question'>
              - When to use the tool?
              </div>
              <div className='response'>
                You can start a search for your current game as soon as champion select is over and the game is loading. <br/>
                If you are not in a game or your game just ended you can scan your last game/the game that just ended to know whether the result matched the algorithm's expectations.
              </div>
            </div>

            <div className='questions-separator'/>

            <div className='question-container'>
              <div className='question'>
                - What does the results mean?
              </div>
              <div className='response'>
              The DOOMscore is a value that goes from 0 to 100. The less likely you are to win the higher your DOOMscore will be.<br/>
              Scores between 30 and 70 means the game is fairly balanced and that there is no major disparity between teams based on their statistics.
              </div>
            </div>
        </div>
    </div>
  );
}

