import "./Stats.css";
import { useState } from "react";
import PulseRingsMultiple from "../components/Loader.tsx";


function StatLoader() {
    return (
                <center>
                    <PulseRingsMultiple/>
                </center>
    );
}


export function convert_score(doomscore) {
    // let doomonumber = Number(doomscore.toFixed(0))*4.16665;

    // if (doomonumber > 16.6 ) {
    //     doomonumber = 16.6 + Math.log(doomonumber-16.6)*5
    // }
    // else if (doomonumber < 16.6) {
    //     doomonumber = -16.6 - Math.log(-doomonumber-16.6)*5
    // }

    // doomonumber = 50 - doomonumber;
    // return doomonumber;



    return 110/Math.PI * Math.atan(-doomscore/6.4) + 50;
}

function Stats_content(dataout, setdata) {


    function handleData(data) {
        if (data === null) {
            setdata({"code": 500});
        }
        else {
                setdata(data);
        }
    }

    if (dataout === undefined) {
        fetch("https://www.amidoomed.app/api/stats", {
	        mode: 'cors',
	        headers: {
		    "Access-Control-Allow-Origin": '*',
		    "Access-Control-Allow-Headers": "Origin, X-Requested-With, Content-Type, Accept"
	    }}
	    ).catch((e) => {
            handleData(null);
        }).then(res => res.json()).then(data => handleData(data));
        return (<StatLoader/>);
    }

    return (
        <div className="data-wrapper">
        
            <div className="stats-wrapper">
                <div className="stat-container">
                    <h1>{dataout["n_search"]}</h1>
                    <h2>scanned accounts</h2>
                </div>
                <div className="stat-container">
                    <h1>{dataout["n_negative"]}</h1>
                    <h2>doomers</h2>
                </div>
                <div className="stat-container">
                    <h1>{dataout["n_positive"]}</h1>
                    <h2>whinners</h2>
                </div>
            </div>
            {Doomed_accounts(dataout["worst_list"])}
        </div>
    )
}



function Doomed_accounts(data) {
    function Display_account(entry) {
        return (
            <div className="account-display">
                <h1>{entry[1]}</h1>
                <h3>{Number(convert_score(entry[0]).toFixed(2))}</h3>
            </div>
        )
    }


    let accounts_map = data.map((e) => Display_account(e));

    return (
        <div className="doomed-container">
            <h2>Most doomed users last week:</h2>
            <div className="doomed-people">
                {accounts_map}
            </div>
        </div>
    )
}


export default function Stats() {

    const [dataout, setdata] = useState(undefined);


    return (
        <div className="footer-adj">
            {Stats_content(dataout, setdata)}
        

            <div className="stats-separator"></div>

            <p style={{
                color: "#434f64",
                fontSize: "1.3vw"
            }}>
                You can search your username and get a score for your current or last game as shown below:
            </p>

            <div className="example-container">
                <img
                    className="examples"
                    src={require("./content/example1.png")}
                    alt={"site search bar"}
                />
                <img
                    className="examples"
                    src={require("./content/example2.png")}
                    alt={"site search bar"}
                />
            </div>
        </div>
    )
}
