
import '../Pages/Front.css';
import React, { useState } from "react";


function convert_name(s) {
  return s.replace('#', '%23');
}

function SearchBar() {
  const [dropdown, setdrop_down] = useState(false);
  const [selectedServer, setServer] = useState("EUW");

  function handleSubmit(e) {
    e.preventDefault();
    const form = e.target;
    const formData = new FormData(form);

    const formJson = Object.fromEntries(formData.entries());
    const name = convert_name(formJson.name);
    if (name !== "") {
      window.location.href = `/result/${selectedServer}/${name}`;
    }
  }

  function handleChange(e) {
    return;
  }

  function handleSelect() {
    //setdrop_down(true);
  } 

  function handleDeSelect() {
    //setdrop_down(false);
  } 



  return (
    <div className='searchbar-wrapper'>
          <form onSubmit={handleSubmit} onChange={handleChange} onSelect={handleSelect} onBlur={handleDeSelect} autoComplete='off' className='form-bar-wrapper'>
                <select className='bar-server-select'
                value={selectedServer}
                onChange={e => setServer(e.target.value)}>
                  <option value={"EUW"}>
                    &nbsp;EUW
                  </option>
                  <option value={"EUNE"}>
                    &nbsp;EUNE
                  </option>
                  <option value={"NA"}>
                    &nbsp;NA
                  </option>
                </select>
                <label>
                    <input
                        type="text"
                        name="name"
                        maxLength="128"
                        className="input-text-bar"
                        placeholder="Username#Tagline"
                    />
                    {dropdown ? <div className='list-players'>how</div> : null}
                </label>
                <button className='submit-button'>
                    <input type="submit" value=" "/>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 600 600" width="40px" height="40px" className='submit-svg' fill='#14181F'>
                        <path d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM164.7 328.7c22-22 53.9-40.7 91.3-40.7s69.3 18.7 91.3 40.7c11.1 11.1 20.1 23.4 26.4 35.4c6.2 11.7 10.3 24.4 10.3 35.9c0 5.2-2.6 10.2-6.9 13.2s-9.8 3.7-14.7 1.8l-20.5-7.7c-26.9-10.1-55.5-15.3-84.3-15.3h-3.2c-28.8 0-57.3 5.2-84.3 15.3L149.6 415c-4.9 1.8-10.4 1.2-14.7-1.8s-6.9-7.9-6.9-13.2c0-11.6 4.2-24.2 10.3-35.9c6.3-12 15.3-24.3 26.4-35.4zm-31.2-182l89.9 47.9c10.7 5.7 10.7 21.1 0 26.8l-89.9 47.9c-7.9 4.2-17.5-1.5-17.5-10.5c0-2.8 1-5.5 2.8-7.6l36-43.2-36-43.2c-1.8-2.1-2.8-4.8-2.8-7.6c0-9 9.6-14.7 17.5-10.5zM396 157.1c0 2.8-1 5.5-2.8 7.6l-36 43.2 36 43.2c1.8 2.1 2.8 4.8 2.8 7.6c0 9-9.6 14.7-17.5 10.5l-89.9-47.9c-10.7-5.7-10.7-21.1 0-26.8l89.9-47.9c7.9-4.2 17.5 1.5 17.5 10.5z"/>
                    </svg>
                </button>
            </form>



    </div>
  );
}

export default SearchBar;
