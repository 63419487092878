import './Donate.css';

export default function Donate() {


  return (
    <div className="donate-wrapper">
        Donate
    </div>
  );
}

