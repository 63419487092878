import React from "react";
import ReactSpeedometer from "react-d3-speedometer";

const styles = {
  dial: {
    display: "inline-block",
    width: `auto`,
    height: `auto`
  },
  title: {
    fontSize: "1em",
    color: "white"
  }
};



const Speedometer = ({ value, tcolor, threshold, user }) => {

  const neg_threshold = 100-threshold;

  // changer la formule pour match l'affichage
  return (
    
    <div style={styles.dial}>

    <div className="doom-text">
        {user} final Doomscore:
        <br/>
      </div>
      <center>
      <ReactSpeedometer
        maxValue={100}
        minValue={0}
        height={190}
        width={290}
        value={value}
        needleTransition="easeIn"
        needleTransitionDuration={1500}
        ringWidth={10}
        needleHeightRatio={0.7}
        needleColor="#D1DAE7"
        customSegmentStops={[0, threshold, neg_threshold, 100]}
        // valueFormat={'2'}
        // segmentValueFormatter={(value)=>'Manav'}
        // startColor="green"
        segments={3}
        // customSegmentLabels={['ma','s','d']}
        segmentColors={["#33B101", "#F8D402", "#D30B02"]}
        // endColor="blue"
        // currentValueText={`Doomscore: ${Number((value).toFixed(0))}`}
        currentValueText=" "
      />
      </center>
      
      {/* <div style={styles.title}>{title}</div> */}
    
      <div style={{
          color: tcolor,
          fontSize: "2vw",
          marginTop: "-1vw"
        }}>
          {Number((value).toFixed(2))}
        </div>
    </div>
  );
};

export default Speedometer;
