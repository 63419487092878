import "../Pages/Result.css";

import { Link } from "react-router-dom";

function Return2Front() {
    return (
        <Link className='return-button' to='/'>
            Return to front page
        </Link>
    )
}

export default function Error404() {
    return (
        <div className='error-wrapper'>
            404
            <br/>
	    <h5>Page not found</h5>
	    <div style={{height: "0.5vw"}}/>
            <Return2Front/>
        </div>
    );
}

